<template>
  <v-container fluid :class="selected.length > 0 ? 'pb-16' : ''">
    <v-row>
      <v-col cols="4">
        <SearchBar
          @search="
            filters.title = $event;
            getItems();
          "
          ref="searchBar"
        />
      </v-col>
      <v-col align="right">
        <MassArchiveFormsFilters
          :filters="filters"
          @applyFilters="
            filters = JSON.parse(JSON.stringify($event));
            getItems();
            $refs.searchBar.setSearch(filters.title);
          "
          :projects="projects"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            Forms
            <v-badge
              inline
              class="ml-2"
              bordered
              :content="itemsCount"
              :value="itemsCount"
            >
            </v-badge>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              class="colored-table-check-box"
              v-model="selected"
              :headers="headers"
              :items="items"
              :server-items-length="itemsCount"
              disable-pagination
              hide-default-footer
              show-select
              :item-class="itemClass"
              must-sort
              :options.sync="options"
            >
              <template v-slot:[`item.createdOnMobileAt`]="{ item }">
                {{ item.createdOnMobileAt | filterAsLocalDate }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <MassArchiveFormsMassManage
      :selected="selected"
      v-if="selected.length > 0"
      @unselect="selected.splice($event, 1)"
      @unselectAll="selected = []"
      @archive="archive"
      @unarchive="unarchive"
    />
  </v-container>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import MassArchiveFormsFilters from '@/components/Filters/MassArchiveFormsFilters.vue';
import MassArchiveFormsMassManage from '@/components/MassArchiveForms/MassArchiveFormsMassManage.vue';
export default {
  name: 'MassArchiveFormsPage',
  components: {
    SearchBar,
    MassArchiveFormsFilters,
    MassArchiveFormsMassManage,
  },
  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  computed: {
    projects() {
      const result = [];
      this.items.forEach((item) => {
        const index = result.findIndex(
          (project) => project.id == item.projectId,
        );
        if (index == -1) {
          result.push({
            id: item.projectId,
            name: item.projectName,
          });
        }
      });
      return result;
    },
  },
  data() {
    return {
      items: [],
      itemsCount: 0,
      headers: [
        {
          text: 'Form title',
          value: 'userEnteredLabel',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Project',
          value: 'projectName',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Assigned date',
          value: 'createdOnMobileAt',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Assigned user',
          value: 'assignedTo',
          align: 'start',
          class: 'lightGrey',
        },
      ],
      filters: JSON.parse(
        JSON.stringify(this.$constants.MASS_ARCHIVE_FORMS_FILTERS),
      ),
      selected: [],
      options: {
        sortBy: ['createdOnMobileAt'],
        sortDesc: [true],
      },
    };
  },
  methods: {
    getItems() {
      const url = 'get-assigned-forms-by-company?format=json';
      const params = {
        filters: this.filters,
        loaderText: 'Loading...',
        options: this.options,
      };
      this.$axios.post(url, params).then((response) => {
        this.items = response.data;
        this.itemsCount = response.data.length;
      });
    },
    itemClass(item) {
      return item.isArchived ? 'red lighten-4' : '';
    },
    archive() {
      const url = 'archive-assigned-forms?format=json';
      const params = {
        ids: this.selected.map((item) => item.id),
        loaderText: 'Loading...',
      };
      this.$axios.post(url, params).then(() => {
        this.selected = [];
        this.getItems();
      });
    },
    unarchive() {
      const url = 'unarchive-assigned-forms?format=json';
      const params = {
        loaderText: 'Loading...',
        ids: this.selected.map((item) => item.id),
      };
      this.$axios.post(url, params).then(() => {
        this.selected = [];
        this.getItems();
      });
    },
  },
  beforeMount() {
    //pinging the server just to fore a session on home page
    this.$axios.post('public/ping?format=json', {});
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style></style>
