<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            :color="filterApplied ? 'orange' : 'msaBlue'"
            dark
            v-blur
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="resetFilterFields"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        Filter
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="headline msaBlue white--text">
        <v-row>
          <v-col class="grow">Filters</v-col>
          <v-col class="shrink">
            <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Form Title </v-col>
          <v-col class="pb-2">
            <v-text-field
              v-model="thisFilters.title"
              outlined
              hide-details
              placeholder="Enter a name"
              dense
              clearable
              data-testid="form-title-name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Project </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              deletable-chips
              :value="thisFilters.projectIds"
              multiple
              label="Select project(s)"
              hide-details
              :items="projects"
              clearable
              @change="thisFilters.projectIds = $event"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Created Date </v-col>
          <v-col class="pb-2" cols="6">
            <v-menu
              v-model="createdFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.createdFrom"
                  label="From"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.createdFrom"
                no-title
                @input="createdFromMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="createdToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.createdTo"
                  label="To"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.createdTo"
                no-title
                @input="createdToMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Show Archived </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.showArchived"
              dense
              mandatory
              color="msaBlue"
              style="width: 100%"
            >
              <v-btn style="width: 50%" class="text-capitalize" :value="1">
                Yes
              </v-btn>
              <v-btn style="width: 50%" class="text-capitalize" :value="0">
                No
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="clear()"> clear </v-btn>
        <v-btn class="msaBlue white--text" @click="apply()"> apply </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MassArchiveFormsFilters',
  props: {
    filters: {
      type: Object,
    },
    projects: {
      type: Array,
    },
  },
  computed: {
    filterApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.MASS_ARCHIVE_FORMS_FILTERS)
      );
    },
  },
  data() {
    return {
      dialog: false,
      thisFilters: { ...this.$constants.MASS_ARCHIVE_FORMS_FILTERS },
      createdFromMenu: false,
      createdToMenu: false,
    };
  },
  methods: {
    apply() {
      this.fixNulls();
      this.$emit('applyFilters', this.thisFilters);
      this.close();
    },
    clear() {
      this.thisFilters = JSON.parse(
        JSON.stringify(this.$constants.MASS_ARCHIVE_FORMS_FILTERS),
      );
      this.apply();
    },
    close() {
      this.dialog = false;
    },
    fixNulls() {
      if (this.thisFilters.title == null) {
        this.thisFilters.title = '';
      }

      if (this.thisFilters.createdFrom == null) {
        this.thisFilters.createdFrom = '';
      }

      if (this.thisFilters.createdTo == null) {
        this.thisFilters.createdTo = '';
      }
    },
    resetFilterFields() {
      this.thisFilters = { ...this.filters };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
