<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="green"
        overlap
        :content="selected.length"
        :value="selected.length"
        :style="`position: fixed; bottom: 45px; z-index: 80; left: ${floatBtnLeftPosition}px`"
      >
        <v-btn
          elevation="5"
          class="amber lighten-1 msaBlue--text"
          large
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="pr-2"> mdi-file-document-multiple-outline </v-icon>
          Selected forms
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Mass Management </v-col>
          <v-col class="shrink">
            <v-btn icon @click="close()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead class="lightBg">
                <tr>
                  <th class="text-left">Selected Forms</th>
                  <th class="text-right">Remove From Selection</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in selected" :key="item.id">
                  <td>
                    {{ item.userEnteredLabel }}
                  </td>
                  <td align="right">
                    <v-btn icon color="red" @click="$emit('unselect', i)">
                      <v-icon> mdi-minus-circle-outline </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="msaBlue white--text"
          @click="archiveAll"
          :disabled="disableArchive"
        >
          Archive all
        </v-btn>
        <v-btn
          class="red white--text"
          @click="unarchiveAll"
          :disabled="disableUnarchive"
        >
          Unarchive all
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text dark color="red" @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MassArchiveFormsMassManage',
  props: {
    selected: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    floatBtnLeftPosition() {
      return window.innerWidth / 2 - 249 / 2;
    },
    disableArchive() {
      return this.selected.every((item) => item.isArchived);
    },
    disableUnarchive() {
      return this.selected.every((item) => !item.isArchived);
    },
  },
  methods: {
    cancel() {
      this.$emit('unselectAll');
      this.close();
    },
    close() {
      this.dialog = false;
    },
    archiveAll() {
      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to archive the selected forms?',
        () => this.$emit('archive'),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    unarchiveAll() {
      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to unarchive the selected forms?',
        () => this.$emit('unarchive'),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
  },
};
</script>

<style>
/* override publish button disabled style */
#publish-btn-toggle .v-btn--disabled.publish-btn {
  background-color: #3564ac !important;
  color: white !important;
}

#publish-btn-toggle .v-btn--disabled.publish-btn .publish-icon {
  color: #4caf50 !important;
}
</style>
<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
